<template>
  <widget-content v-if="modelStores.length" :bg="model" :style="storeStyle" class="store-widget-wrap">
    <div :class="titleBackgroundClass" class="flex justify-center store-title-rich-wrap">
      <rich-text
        v-if="model.titleVisible"
        v-model="model.title"
        :class="$store.getters.isDesktop && 'xl:text-[40px]'"
        :disabled="!editing"
        :editing="editing"
        :family.sync="model.titleFamily"
        :stroke.sync="model.titleStroke"
        class="text-[20px] w-full title"
        placement="bottom"
        theme="snow"
      />
    </div>
    <!-- 修改商品布局组件 -->
    <div
      v-if="isShowLayoutBtn"
      class="store-menu__icon"
      @click="infoMenuVisible = !infoMenuVisible"
    >
      <InfoMenuIcon v-if="infoMenuVisible"/>
      <i v-else class="el-icon-menu"></i>
    </div>

    <!-- 商品导航组件 -->
    <div v-if="isShowStoreNav" class="nav-container relative">
      <div class="nav-wrap relative">
        <div
          v-for="(s, index) in modelStores"
          :key="s.key"
          :style="groupNameStyle"
          class="nav-wrap__item"
          @click="scrollGoodGroup(s, index)"
        >
          <span>{{ s.__groupName }}</span>
        </div>
      </div>
    </div>
    <bg-style
      v-for="s in modelStores"
      :id="`${model.id}-group-${s.key}`"
      :key="s.key"
      v-imloading="pushing"
      :bg="{ ...s, ...{ backgroundBlur: model.backgroundBlur } }"
      :class="{ 'store-item': true, empty: !(s.__goods && s.__goods.length) }"
    >
      <div v-if="model.groupNameVisible" class="group-name">
        <span :style="groupNameStyle">
          {{ s.__groupName || $t('menu.shopSection', {number: s.key}) }}
        </span>
      </div>
      <!-- 商店板块 -->
      <div
        ref="store"
        class="store-goods-wrap"
      >
        <div
          v-if="s.select[0].value && s.select[1].value"
          :class="[
            'store-goods'
          ]"
        >
          <OneGood
            v-for="(good, index) in s.__goods"
            :key="good.code"
            :editing="editing"
            :good="good"
            :info-menu-visible="infoMenuVisible"
            :is-last-one="index === s.__goods.length - 1"
            :is-mobile="isMobile"
            :model="model"
            :show-car="showCar"
            :store-lang="storeLang"
            @buy="buy"
            @show-detail="onShowDetail(good)"
          />
        </div>
        <!-- 商品模板（未选取商品时展示） -->
        <div
          v-if="!s.select?.[1]?.value && editing"
          :class="[
            'flex',
            'flex-wrap',
            'store-goods',
            'skeleton'
          ]"
        >
          <OneGood
            v-for="good in 4"
            :key="good"
            :editing="editing"
            :good="{names: good, activities: [], id: good}"
            :info-menu-visible="infoMenuVisible"
            :is-mobile="isMobile"
            :model="model"
            :show-car="showCar"
            :store-lang="storeLang"
            is-tmp
            @show-detail="onShowDetail(good)"
          />
        </div>
      </div>
    </bg-style>
    <!-- 支付结果 -->
    <payresult v-model="showpayresult" :device="device" @close="showpayresult = false"></payresult>
    <!--PC端弹框 -->
    <DeskPayModal
      v-if="deskPay.visible"
      :desk-pay="deskPay"
      :lang-id-data="langIdData"
      :order-data="orderData"
      :store-lang="storeLang"
      @close="closePayModal"
    />
    <goods-detail ref="detail" :good="currentGoods" :is-mobile="isMobile" :model="model" :store-lang="storeLang"
                  @buy="buy"/>
  </widget-content>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import tinycolor from 'tinycolor2'
import isNil from 'lodash.isnil'
import cloneDeep from 'lodash.clonedeep'
import {Message} from "element-ui";
import OneGood from './oneGood/index.vue'
import GoodsDetail from './detail.vue'
import tools, {checkIgnoreMessage} from '~/utils'
import { getIpInfo } from "~/site/core";
import WidgetContent from '~/site/components/widgetContent'
import payresult from '~/components/pay/payresult.vue'
import DeskPayModal from '~/components/pay/deskPayModal.vue'
import Bus, {emitWinBus} from '~/site/model/bus'
import richText from '~/components/richText'
import {isMobile} from '~/utils/types'
import {GET_SITE_ID, default_site_lang, PAY_URL, isBuildWebSite, isPre} from '~/config'
import InfoMenuIcon from '~/assets/svg/icon/info-menu.svg?inline'
import {DeviceEnum} from '~/enums/deviceEnum';
import {scrollTo, getTitleBackgroundClass} from '~/utils/dom'

let isPreviewMode = !isBuildWebSite
let isSandBox = !isBuildWebSite // 是否是建处出来的网站，检出来的网站用正式模式其他的用沙盒模式
const SiteID = GET_SITE_ID()
// eslint-disable-next-line no-console
if (isBuildWebSite && isPre && SiteID.startsWith('56') && SiteID.length === 2) {
  // myCard验收商城
  isSandBox = isPreviewMode = true
}
export default {
  name: 'StoreWidgetEdit',
  components: {
    richText,
    GoodsDetail,
    payresult,
    WidgetContent,
    OneGood,
    InfoMenuIcon,
    DeskPayModal
  },

  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      currentGroup: 1,
      currentGoods: null,
      that: this,
      lang: tools.getLang(this),
      copyStores: [],
      titleBackgroundClass: '',
      storeLang: default_site_lang,
      showpayresult: false,
      currencies: [],
      currencieMap: null,
      pushing: false, //  加购中
      return_url: '', // 支付返回地址
      infoMenuVisible: true,
      creating: false,
      // PC端支付弹框
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      },
      orderData: {}
    }
  },
  computed: {
    ...mapState({
      realDevice: (state) => state.device.device,
      userInfo: (state) => state.user.siteUserInfo,
      projectInfo: (state) => state.project.info.project,
      projectId: (state) => state.project.projectId,
      isUpdateUuid: state => state.user.isUpdateUuid,
      langIdData: state => state.locale.langIdData,
      shopGood: state => state.site.shopGood
    }),
    titleText() {
      const res = this.model.layout.find((m) => {
        return m.key === 'storeTitle'
      })
      return res && res.titleText
    },
    modelStores() {
      return this.editing ? this.model.stores : this.model.stores.filter(item => item.__goods?.length)
    },
    showCar() {
      const res = this.model.layout.find((m) => {
        return m.key === 'car'
      })
      return res && res.value
    },
    groupNameStyle() {
      if (this.model?.groupNameColorShow) {
        return {
          color: this.model.groupNameColor,
        }
      }
      return {}
    },

    isMobile() {
      const device = this.editing
        ? this.device
        : this.realDevice
      return device === DeviceEnum.MOBILE
    },

    /**
     * 非编辑状态下，没有商品，则不展示布局按钮
     */
    isShowLayoutBtn() {
      const hasGoods = this.model?.stores.some(store => !!store?.__goods?.length)
      const isNormalGoodLayout = this.model.goodsLayoutMethod === 'normal'
      return this.isMobile && hasGoods && isNormalGoodLayout
    },
    /**
     * 商品组小于 2，则不展示导航
     */
    isShowStoreNav() {
      return this.model?.stores?.length >= 2
    },
    priceOpacityColor() {
      const color = tinycolor(this.model.goodsPriceColor)
      color.setAlpha(0.8)
      return color.toRgbString()
    },
    priceOpacity50() {
      const color = tinycolor(this.model.goodsPriceColor)
      color.setAlpha(0.5)
      return color.toRgbString()
    },
    storeStyle() {
      const nameColor = tinycolor(this.model.goodsTitleColor)
      return {
        '--price-opacity-color': this.priceOpacityColor,
        '--price-color-50': this.priceOpacity50,
        '--goods-name-color': this.model.goodsTitleColorShow ? nameColor.toRgbString() : null,
        '--goods-name-color30': this.model.goodsTitleColorShow ? nameColor.setAlpha(0.7).toRgbString() : null
      }
    }
  },
  async created() {
    // 获取商店语言环境 ，设计时默认en, 运行时取路由参数
    // eslint-disable-next-line
    this.storeLang = tools.getStoreLang(this)

    // 获取货币列
    await this.getCurrencies()

    // 获取商品信息
    this.pushing = (!this.isUpdateUuid && !this.editing)
    this.getAllGoods()
    await this.initShopCart()

  },
  mounted() {
    this.titleBackgroundClass = getTitleBackgroundClass()
    this.copyStores = cloneDeep(this.model.stores)
    Bus.$on('updateGood', (sitem, gitem) => {
      const params = {
        group_id: gitem.value,
        pageSize: 100,
        pageNo: 1,
      }
      this.getGoods(params, sitem, this.editing)
    })

    Bus.$on('reloadGood', async (type) => {
      if (type === 'login-out') {
        this.$store.commit('site/SET_SHOP_GOOD', null)
      }
      if (type === 'login') {
        this.checkBuy()
      }
      await getIpInfo(this)
      this.getAllGoods()
      await this.getShopCart(this)
    })

    // 检查是否是支付页面跳转回来的
    this.checkStatus()
    this.return_url = `${window.location.protocol}//${window.location.hostname === 'localhost' ? 'sitebuilder.im30.net' : window.location.host}${window.location.pathname}`
  },
  destroyed() {
    Bus.$off('updateGood')
    // Bus.$off('sortChange')
    Bus.$off('reloadGood')
  },
  methods: {
    ...mapMutations({
      'SET_STORE_GOODS': 'site/SET_STORE_GOODS'
    }),
    ...mapActions({
      'getShopCart': 'goods/getShopCart'
    }),
    onShowDetail(good) {
      if (this.editing || !good) return
      this.currentGoods = cloneDeep(good)
      // this.$store.commit('site/SET_SHOP_GOOD', cloneDeep(good))
      this.$refs.detail.init()
    },
    async initShopCart() {
      if (this.editing || !this.$store.state.user.siteLoginUserInfo) return
      await this.getShopCart(this)
    },
    getAllGoods() {
      this.model.stores.forEach((s) => {
        const params = {
          group_id: s.select[1].value,
          pageSize: 100,
          pageNo: 1,
          project_id: this.projectId
        }
        this.getGoods(params, s, this.editing)
      })
    },
    emitWinBusInit() {
      this.$nextTick(() => {
        emitWinBus('goods-init', this.$refs.store)
      })
    },
    // 获取商品信息
    async getGoods(params, s, editing) {
      if (!params.group_id) {
        this.pushing = false
        return
      }
      const [err, result] = await this.$utils.to(this.$api.good.getVirtualItems(params, {editing}))
      this.pushing = false
      if (!err && result) {
        if (result?.discount_info?.discount_level) {
          this.$store.commit('goods/SET_DISCOUNT_INFO', cloneDeep(result.discount_info))
        }
        const groupEnabled = result.group && result.group.enabled
        const data = groupEnabled ? result.data.map(item => {
          const newItem = {...item}
          newItem.activity_rule = JSON.parse(newItem.activity_rule || '{}')
          return newItem
        }) : []
        const tempGoods = data ? data.filter((item) => item.enabled) : []
        this.$set(s, '__goods', tempGoods)
        this.SET_STORE_GOODS(cloneDeep(this.model.stores))
        const names = result?.group?.names || {}
        const {lang_id} = names
        const groupName = lang_id ? (this.langIdData[lang_id] || lang_id) : names[this.storeLang]
        this.$set(s, '__groupName', groupName || '')
        this.emitWinBusInit()
      } else {
        this.$imessage({
          content: err?.message || err,
          type: 'warning',
        })
      }
    },
    checkBuy() {
      this.shopGood && this.createOrder(cloneDeep(this.shopGood))
    },
    // 购买
    buy(good) {
      if (this.editing) return
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('site/SET_SHOP_GOOD', cloneDeep(good))
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
        this.$refs.detail.cancel()
        return
      }
      this.createOrder(good)
    },
    createOrder(good) {
      if (this.creating || !good?.id) return
      this.creating = true
      const StoreIpInfo = tools.getLangByip(this)
      const {currency} = StoreIpInfo
      const {
        project_id,
        merchant_id
      } = this.userInfo
      let ipInfo = {}
      try {
        ipInfo = tools.getxfip(localStorage)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
      const priceInfo = tools.getPriceInfo(good.prices_setting, StoreIpInfo, this);
      const price = +priceInfo.price;
      const device = isMobile() ? 'mobile' : 'desktop'
      const lang = tools.getNeedParamsFormRouteParams(this.$route.params)?.lang || 'en'
      const sandbox = isSandBox ? 1 : 0  //  true : false
      const params = {
        project_id,
        merchant_id,
        currency,
        amount: price,
        from_preview_mode: isPreviewMode,
        items: [
          {
            item_id: good.id,
            num: 1,
            amount: price,
            price,
            currency,
            virtual_currency: currency,
            virtual_currency_amount: price,
          },
        ],
        settings: {
          view_source: this.$route.query.from,
          device,
          language: lang,
          show_order: false,
          return_url: this.return_url,
        },
      }
      this.orderData = cloneDeep(params)
      this.$api.order.createOrder(params, ipInfo).then((res) => {
        if (res.token) {
          this.openPayModal(res, sandbox, device)
        }
      }).catch((err) => {
        !checkIgnoreMessage(err) && Message.error(err)
      })
        .finally(() => {
          this.creating = false
        })
    },
    // open pay modal PC
    openPayModal(res, sandbox, device) {
      const {token, code} = res
      let payUrl = ''
      const env = process.env.RUN_ENV
      const isGoPay = token.slice(0, 2) === 'v2'
      if (isGoPay) {
        payUrl = `${PAY_URL[env]}/new-kop/payelement?access_token=${token}&sandbox=${+sandbox}`
      } else {
        payUrl = `${PAY_URL[env]}/pay/payelement?access_token=${token}&sandbox=${+sandbox}`
      }
      if (device === 'mobile') {
        this.$store.commit('site/SHOW_ORDER_MODAL', {code, payUrl})
      } else {
        this.deskPay = {
          visible: true,
          isGoPay,
          payUrl,
          orderCode: code
        }
      }
    },
    closeDetail() {
      this.$refs.detail.cancel()
    },
    // close pay modal PC
    closePayModal() {
      this.closeDetail()
      // 清空待购买商品
      this.$store.commit('site/SET_SHOP_GOOD', null)
      // 购买结束刷新商品
      this.getAllGoods()
      Bus.$emit('reloadActivity', 'payment')
      // 关闭弹框
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    },
    checkStatus() {
      const paymentId = ''
      if (paymentId) {
        if (!tools.getQueryString('isHide')) {
          if (window.showpayresult) return
          this.showpayresult = Boolean(paymentId)
          window.showpayresult = true
        }
      }
    },

    // 获取货币列表
    async getCurrencies() {
      const res = await this.$api.common.getCurrencies()
      this.currencies = res.data
      this.currencieMap = {}
      this.currencies.forEach((c) => {
        this.currencieMap[c.id] = c
      })
      this.$store.commit('project/SET_CURRENCYINFO', {
        currency: this.currencies,
        currencieMap: this.currencieMap,
      })
    },
    scrollGoodGroup(group, index) {
      this.currentGroup = index
      scrollTo(`${this.model.id}-group-${group.key}`, 'site-body', 50)
    }
  },
}
</script>

<style lang="less" scoped>
.normal-goods {
  column-gap: 32px;
}

.store-widget-wrap {
  position: relative;
  background-size: 100%;
  padding: 80px 0;
  overflow: hidden;

  .nav {
    &-container {
      width: 100%;
      margin-bottom: 46px;
    }

    &-wrap {
    @apply flex items-center justify-center;
      column-gap: 60px;

      &__item {
      @apply cursor-pointer;
        font-size: 16px;
      }
    }
  }

  .back-color-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .store-title-rich-wrap {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: var(--title-background-image);
    margin-bottom: 50px;
  }

  .store-item {
    padding: 0 0 36px 0;
    position: relative;
    background-size: 100%;
    min-height: 300px;

    .back-color-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .group-name {
      text-align: center;
      position: relative;
      margin-bottom: 40px;

      span {
        font-size: 26px;
        color: var(--text-color-alpha-06);
      }
    }

    .store-goods-wrap {
      display: flex;
      justify-content: center;

      .store-goods {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    &.empty {
      min-height: auto;
      padding: 0 !important;
    }
  }

  .x-auto {
    overflow-x: auto;
    overflow-y: hidden;
  }

  // 兼容线上环境样式丢失
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }
}

.site-device-750 {
  .store-title-rich-wrap {
    margin-bottom: 24px;
  }

  .nav {
    &-container {
      width: 100%;
      margin-bottom: 10px;
    }

    &-wrap {
    @apply flex items-center justify-center;
      column-gap: 30px;

      &__item {
      @apply cursor-pointer;
        font-size: 12px;
      }
    }
  }

  .store-widget-wrap {
    min-width: auto;
    padding: 24px 0;

    .store-title-rich-wrap {
      .title-rich {
        .ql-toolbar.ql-snow {
          top: 0;
        }
      }
    }

    .store-item {
      padding: 12px 0 16px 0;

      &:last-child {
        padding-bottom: 0;
      }

      .group-name {
        margin: 8px 0 16px;

        span {
        @apply text-16;
        }
      }

      .store-goods-wrap {
        .store-goods {
        @apply w-full overflow-hidden mb-5;
          column-gap: 0;
          padding: 0;

          &:last-child {
          @apply mb-0;
          }
        }
      }
    }
  }
}

.store-menu__icon {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 20px;
  height: 20px;
  font-size: 20px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}
</style>
<style lang="less">
.store-widget-wrap {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert !important;
  }
}
</style>
